// Containers
.container-fluid {
	&--primary {
		background-color: $primary;
		color: white;

		h1, h2, h3, h4, h5 {
			color: white;
		}
	}

	&--secondary {
		background-color: $secondary;
	}

	&--success {
		background-color: $success;
	}

	&--white {
		background-color: white;
	}

	&--grey {
		background-color: $gray-100;
	}
}

.primary {
	background-color: $primary;
	color: white;

	h1,h2,h3,h4,h5,h6 {
		color: white;
	}
}

.secondary {
	background-color: $secondary;
	color: white;

	h1,h2,h3,h4,h5,h6 {
		color: white;
	}
}

.gray-100 {
	background-color: $gray-100;
}