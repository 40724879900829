.price-block {
	background-color: $gray-100;
	padding-top: 100px;
	padding-bottom: 100px;
	
	@media(min-width: 1200px) {
		background-image: url('/wp-content/themes/acetravel/assets/images/Aeroplane_Illustration.png');
		background-repeat: no-repeat;
		background-size: 500px;
		background-position: top right;
	}

	&__block {
		padding: 4rem;
    border-radius: 30px;
		color: white;
		height: 100%;

		@media(min-width: 1200px) {
			border-radius: 220px;
		}

		h3 {
			color: white;
			margin-bottom: 30px !important;
			margin-top: 30px !important;
			font-size: 1.8rem !important;
		}

		h4 {
			color: #f4e075;
			margin-bottom: 12px !important;
		}

		.btn {
			background-color: #f4e075;
			&:hover {
				opacity: 0.9;
			}
		}
	}
}

.price-block__block-wrapper {
	margin-top: 2rem;
}

.price-block__block-wrapper:nth-child(2) {
	.price-block__block {
		color: $primary;
		h3 {
			color: $primary;
		}

		h4 {
			color: $primary;
		}

		.btn {
			background-color: #283a71;
			color: white !important;
		}
	}
}