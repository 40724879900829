// Hero
.hero {
	padding-top: 100px;

	&--default {
		background-color: $gray-100;

		.btn {
			margin-top: 50px;

			&:hover {
				color: white !important;
				background-color: $primary !important;
			}
		}

		.btn:last-child {
			border-color: $secondary !important;
			color: $secondary !important;

			&:hover {
				color: white !important;
				background-color: $secondary !important;
			}
		}
	}

	&--primary {
		background-color: $primary;
		color: white;

		h1,h2,h3,h4,h5,h6 {
			color: white;

			strong {
				color: white !important;
			}
		}

		.btn {
			background: transparent !important;
			color: white !important;
			border-color: white !important;

			&:hover {
				color: $primary !important;
				background-color: white !important;
			}
		}
	}

	&--secondary {
		background-color: $secondary;
		color: white;

		h1,h2,h3,h4,h5,h6 {
			color: $primary;

			strong {
				color: $primary !important;
			}
		}

		.btn {
			background: transparent !important;
			border-color: $primary !important;
			color: $primary !important;

			&:hover {
				color: white !important;
				background-color: $primary !important;
			}
		}
	}

	&--home {
		padding-bottom: 100px;

		img {
			display: none;

			@media(min-width: 992px) {
				display: block;
				position: absolute;
    		right: 50px;
    		bottom: -50px;
			}
		}

		.book-a-demo {
			background-color: $primary;
			border-radius: 15px;
			color: white;

			.btn {
				margin-top: 15px;
	    	margin-bottom: 0;
			}

			h2 {
				color: white;
				margin-bottom: 30px !important;
			}

			&__image {
				background-color: $secondary;
				border-top-left-radius: 15px;
				border-bottom-left-radius: 15px;

				img {
					position: absolute;
			    left: -27px;
			    top: 0;
			    bottom: 0;
			    margin-top: auto;
			    margin-bottom: auto;
				}
			}

			&__form {
				padding: 40px !important;
			}
		}
	}

	&--contact {
		padding-bottom: 100px;
		background-color: $primary;
		color: white;

		h1 {
			color: white;
		}

		img {
			display: none;

			@media(min-width: 992px) {
				display: block;
				position: absolute;
    		right: 50px;
    		bottom: -30px;
			}
		}

		.book-a-demo {
			background-color: white;
			border-radius: 15px;

			.btn {
				margin-top: 15px;
	    	margin-bottom: 0;
			}

			h2 {
				color: $primary;
				margin-bottom: 30px !important;
			}

			&__image {
				background-color: $secondary;
				border-top-left-radius: 15px;
				border-bottom-left-radius: 15px;

				img {
					position: absolute;
			    left: -27px;
			    top: 0;
			    bottom: 0;
			    margin-top: auto;
			    margin-bottom: auto;
				}
			}

			&__form {
				padding: 40px !important;
			}

			.btn-secondary {
				background-color: $primary !important;
				border-color: $primary !important;

				&:hover {
					background: $secondary !important;
					border-color: $secondary !important;
				}
			}
		}
	}

	h1 {
		font-family: $font-family-sans-serif;
	}
}