// FAQs
.faqs {
	margin-bottom: 5rem;
	&__block {
		.card {
			border-radius: 15px;
			margin-bottom: 15px;
			overflow: hidden;
		}

		.card-header {
			border: 0;
			background-color: $secondary;
			position: relative;
			color: white;

			&:after {
				content: '';
				background-image: url('/wp-content/themes/acetravel/assets/images/arrow-up.png');
				background-size: cover;
				background-repeat: no-repeat;
				height: 40px;
				width: 40px;
				position: absolute;
				top: 5px;
				right: 15px;
			}

			&.collapsed {
				&:after {
					background-image: url('/wp-content/themes/acetravel/assets/images/arrow-down.png');
				}
			}

			p {
				margin-bottom: 0;
				font-weight: 600;
			}
		}
	}
}
