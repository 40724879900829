// Blog
.wp-block-latest-posts__featured-image a {
	display: block !important;
}

.wp-block-latest-posts__featured-image img {
	width: 100% !important;
	max-width: none !important;
	max-height: none !important;
	margin: 1rem 0;
}

.wp-block-latest-posts__post-title {
	font-family: "visby_cfbold", sans-serif;
	font-size: 25px;
	color: $primary !important;
}

.post-navigation {
	display: none;
}

.entry-header {
	.entry-title {
		margin: 1rem 0 !important;
	}
}

.entry-content {
	margin: 1rem 0;
}