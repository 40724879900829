// Footer
footer {
	@include globalPadding;
	font-family: 'visby_cfdemi_bold', Arial; 
	font-size: 16px;

	.btn {
		margin-top: 15px !important;
		margin-bottom: 15px !important;
	}

	.list-inline-item:not(:last-child) {
		margin-right: 30px !important;
	}

	a {
		color: $primary !important;
	}
}