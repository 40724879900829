// Full width content
.full-width-content {
	@include globalPadding;

	&--background {
		@include centerBGImage;
		color: white;

		h1,h2,h3,h4 {
			color: white;
		}
	}
}