// Embed section
.embed-section {
	@include globalPadding;
	background-position: center;
	background-repeat: no-repeat;
	color: white;

	h1,h2,h3,h4 {
		color: white;
	}
}