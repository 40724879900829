// Icon info block
.icon-info-block {
	@include centerBGImage;
	@include globalPadding;
	position: relative;
	color: white;
	text-align: center;

	h1, h2, h3 {
		color: white;
	}

	&:before {
		content: '';
		height: 100%;
		width: 100%;
		background-color: $primary;
		opacity: 0.8;
		z-index: 0;
		position: absolute;
		top: 0;
		left: 0;
	}

	.icon-info-block__carousel {
		&__slide {
			padding-left: 20px;
			padding-right: 20px;

			h3 {
				margin-bottom: 15px !important;
			}

			img {
				height: 100px;
		    margin: 30px auto;
			}

			.btn {
				margin-top: 0;
    		margin-bottom: 0;
			}
		}
	}
}