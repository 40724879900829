// Navbar
.navbar {
	-webkit-transition:padding 0.2s ease;
  -moz-transition:padding 0.2s ease; 
  -o-transition:padding 0.2s ease;        
  transition:padding 0.2s ease; 
}

@media (max-width: 768px) {
  .navbar {
    padding: 15px !important;
  }
}

@media (max-width: 768px) {
  .navbar-brand {
    max-width: 250px;
  }
}

.affix {
  font-size: 16px;
  -webkit-transition:font-size 0.2s linear;
  -moz-transition:font-size 0.2s linear;  
  -o-transition:font-size 0.2s linear;         
  transition:font-size 0.2s linear;  

  img {
  	width: 300px;
  	-webkit-transition:width 0.2s linear;
	  -moz-transition:width 0.2s linear;  
	  -o-transition:width 0.2s linear;         
	  transition:width 0.2s linear;  
  }
}

.navbar-light {
	background-color: white;
}

.navbar-light .navbar-nav .nav-link {
	font-family: 'visby_cfdemi_bold', Arial;
}

.nav > li:last-child > a {
  @media (min-width: 992px) {
    background: $secondary; 
    color: white !important;
    border-radius: 50px;
  }
}

.navbar-expand-lg .navbar-collapse {
  @media (min-width: 992px) {
    align-items: center;
    justify-content: center;
  }
}

.dropdown {
  position:static !important;
}

.dropdown-menu {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important;
  margin-top:0px !important;
  top: 72% !important;
  width:100% !important;
  background-color: $gray-100 !important;
  -webkit-column-count: auto;
  -moz-column-count: auto;
  column-count: auto;
  column-width: 20em;

  @media(min-width: 992px) {
    padding: 3rem !important;
  }
}

@media (min-width: 992px) {
  .dropdown:hover>.dropdown-menu {
    display: block;
  }
}

.dropdown-item {
  color: $gray-200 !important;
  padding: 1rem !important;

  &:hover {
    background-color: $secondary !important;
    color: white !important;
  }
}

@media (max-width: 768px) {
  .navbar-nav .dropdown-menu {
    margin-left: -10px !important;
  }
}