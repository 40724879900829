// Icon info block
.tools {
	@include centerBGImage;
	@include globalPadding;
	position: relative;
	text-align: center;

	.tools__carousel {
		&__slide {
			padding-left: 20px;
			padding-right: 20px;
			position: relative;

			.btn {
				margin-top: 15px;
				margin-bottom: 15px;
			}

			img {
				height: 130px;
		    width: 130px;
		    margin: 30px auto;
			}

			// &:after {
			// 	content: '';
			// 	position: absolute;
			// 	bottom: 0;
			// 	left: 0;
			// 	right: 0;
			// 	margin-left: auto;
			// 	margin-right: auto;
			// 	height: 5px;
			// 	width: 50px;
			// }
		}
	}

	&--primary {
		background-color: $primary;
		color: white;

		h1, h2, h3 {
			color: white;
		}

		.tools__carousel__slide {
			&:after {
				background-color: white;
			}
		}
	}

	&--gray-100 {
		background-color: $gray-100;

		h1, h2, h3 {
			color: $secondary;
		}

		.tools__carousel__slide {
			color: $primary;
			
			&:after {
				background-color: $primary;
			}
		}
	}
}