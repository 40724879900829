.testimonials {
	padding-top: 100px;
	padding-bottom: 100px;
	position: relative;

	@media(min-width: 1200px) {
		&:before {
			content: '';
			width: 400px;
	    height: 370px;
			position: absolute;
			left: -50px;
	    top: 100px;
			background-image: url('/wp-content/themes/acetravel/assets/images/testimonials-before.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		&:after {
			content: '';
			width: 400px;
	    height: 370px;
			position: absolute;
			right: -60px;
	    bottom: 130px;
			background-image: url('/wp-content/themes/acetravel/assets/images/testimonials-after.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			z-index: -1;
		}
	}

	&__carousel {
		.slick-slide {
			margin: 30px;
		}

		&__testimonial {
			position: relative;
			background-color: $primary;
			border-radius: 220px;
			color: white;
			padding: 140px 80px;

			@media (min-width: 992px) {
				min-height: 700px;
			}

			&:before {
				content: '';
				background-image: url('/wp-content/themes/acetravel/assets/images/testimonial-quote-before.svg');
				background-repeat: no-repeat;
				background-size: cover;
				height: 40px;
				width: 40px;
				position: absolute;
				top: 30px;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
			}

			&:after {
				content: '';
				background-image: url('/wp-content/themes/acetravel/assets/images/testimonial-quote-after.svg');
				background-repeat: no-repeat;
				background-size: cover;
				height: 40px;
				width: 40px;
				position: absolute;
				bottom: 30px;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
			}

			&__name {
				color: $secondary;
				font-weight: 700;
				margin-bottom: 5px;
				font-size: 22px;
			}

			&__role {
				color: $secondary;
				font-weight: 300;
			}
		}
	}
}