// Emergency contact section
.emergency-contact-section {
	@include globalPadding;
	background-color: $secondary;
	color: white;

	h1,h2,h3,h4 {
		color: white;

		strong {
			color: white !important;
		}
	}

	a {
  	color: white;

  	&:hover {
  		opacity: 0.8;
  		color: white;
  		text-decoration: none;
  	}
  }

	&__telephone {
		border: 3px solid white;
    border-radius: 50px;
    font-size: 40px;
    padding: 2rem;
    font-family: $font-family-monospace !important;
    margin-top: 50px;

    @media(max-width: 768px) {
    	margin: 0 auto;
    	text-align: center;
    }

    span {
    	margin-right: 30px;

    	img {
    		height: 70px;
    	}
    }
	}
}