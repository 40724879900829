// Mixins

// Center element
@mixin centerVertical {
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin centerBGImage {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

@mixin globalPadding {
	padding-top: 50px;
	padding-bottom: 50px;
}