// Image content left right block
.image-content-left-right-block {
	@include globalPadding;

	@media (max-width: 768px) {
		padding-left: 15px;
    padding-right: 15px;
	}

	li {
  	list-style: none;
  	position: relative;
  	padding-left: 10px;
  	padding-bottom: 10px;

  	&:before {
  		content: '';
  		background-image: url("/wp-content/themes/acetravel/assets/images/plane-icon-primary.svg");
  		position: absolute;
	    left: -20px;
	    top: 4px;
	    height: 20px;
	    width: 20px;
	    background-size: cover;
  	}
  }

  a:visited {
  	color: $primary;
  }
}

.image-content-left-right-block.secondary {
	h2 {
		color: $primary;
	}
	
	li {
  	&:before {
  		content: '';
  		background-image: url("/wp-content/themes/acetravel/assets/images/plane-icon-white.svg");
  	}
  }
}