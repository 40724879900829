// Brand Colours
$primary: #163070;
$secondary: #8EB7E0;
// $success: #F5F4F4;
// $info: purple;
// $warning: red;

// Black and Greyscale
$gray-100: #F5F5F5;
$gray-200: #323232;
$gray-300: #fbfafa;
$gray-400: #e5e8eb;
$gray-500: #7a7a7a;
$white: #fff;

$body-bg: white;
$body-color: $gray-200;

$border-radius: 0;

// Grid
$grid-columns:	24;

// Containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1366px
) !default;

// Fonts
$font-family-sans-serif: 'visby_cfregular', sans-serif;
$font-family-monospace:  'visby_cfbold', sans-serif;
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 1.25rem; // 16px
$font-weight-base: 400;

$h1-font-size: 2.75rem; // 44px
$h2-font-size: 2.25rem; // 36px
$h3-font-size: 1.5rem; // 24px
$h4-font-size: 22px;

$headings-margin-bottom: 1rem;

// Nav
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 0;
$navbar-brand-padding-y: 0;
$navbar-light-color: $primary;

// Navbar
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: 1.15rem;

// Buttons
$btn-padding-y: .75rem;
$btn-padding-x: 1.5rem;

$btn-border-radius:           10px;
$btn-border-radius-lg:        10px;
$btn-border-radius-sm:        10px;

$btn-font-size: 1.115rem;

// Modal
$modal-md: 720px;

// Bootstrap and its default variables
@import "../../../node_modules/bootstrap/scss/bootstrap";