// Logo strip
.logo-strip {
	@include globalPadding;
	text-align: center;

	h2 {
		color: $secondary;
	}

	&__carousel {
		img {
			height: 90px;
		}
	}
}