// Two content columns with options
.two-content-columns-with-options {
	@include globalPadding;

	@media (max-width: 768px) {
		padding-left: 15px;
    padding-right: 15px;
	}

	.two-content-columns-with-options__content {
		color: white;
	  border-radius: 250px;
	  display: block;
	  padding: 120px 65px;
	  margin: 0 auto;
	  width: 60%;

	  @media (min-width: 768px) {
	  	width: 40%;
	  }

	  @media (min-width: 1200px) {
	  	width: 50%;
	  }

	  @media (min-width: 1500px) {
	  	width: 40%;
	  }

	  @media (max-width: 768px) {
	  	padding: 120px 45px;
    	margin: 0 auto;
    	width: 70%;
	  }

	  li {
	  	list-style: none;
	  	position: relative;
	  	padding-left: 10px;
	  	padding-bottom: 10px;

	  	&:before {
	  		content: '';
	  		background-image: url("/wp-content/themes/acetravel/assets/images/plane-icon-white.svg");
	  		position: absolute;
		    left: -20px;
		    top: 4px;
		    height: 20px;
		    width: 20px;
		    background-size: cover;
	  	}
	  }

	  a {
	  	color: white !important;
	  }
	}

	&__primary {
		background-image: url('/wp-content/themes/acetravel/assets/images/dark-blue-illustration.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 78%;

		.two-content-columns-with-options__content {
			background-color: $primary;
			opacity: 0.96;
		}
	}

	&__secondary {
		background-image: url('/wp-content/themes/acetravel/assets/images/light-blue-illustration.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 78%;

		.two-content-columns-with-options__content {
			background-color: $secondary;
			opacity: 0.96;
		}
	}
}