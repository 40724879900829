// Typography
@font-face {
    font-family: 'visby_cfbold';
    src: url('/wp-content/themes/acetravel/assets/fonts/visbycf-bold-webfont.woff2') format('woff2'),
         url('/wp-content/themes/acetravel/assets/fonts/visbycf-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'visby_cfdemi_bold';
    src: url('/wp-content/themes/acetravel/assets/fonts/visbycf-demibold-webfont.woff2') format('woff2'),
         url('/wp-content/themes/acetravel/assets/fonts/visbycf-demibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'visby_cfextra_bold';
    src: url('/wp-content/themes/acetravel/assets/fonts/visbycf-extrabold-webfont.woff2') format('woff2'),
         url('/wp-content/themes/acetravel/assets/fonts/visbycf-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'visby_cfheavy';
    src: url('/wp-content/themes/acetravel/assets/fonts/visbycf-heavy-webfont.woff2') format('woff2'),
         url('/wp-content/themes/acetravel/assets/fonts/visbycf-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'visby_cflight';
    src: url('/wp-content/themes/acetravel/assets/fonts/visbycf-light-webfont.woff2') format('woff2'),
         url('/wp-content/themes/acetravel/assets/fonts/visbycf-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'visby_cfmedium';
    src: url('/wp-content/themes/acetravel/assets/fonts/visbycf-medium-webfont.woff2') format('woff2'),
         url('/wp-content/themes/acetravel/assets/fonts/visbycf-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'visby_cfregular';
    src: url('/wp-content/themes/acetravel/assets/fonts/visbycf-regular-webfont.woff2') format('woff2'),
         url('/wp-content/themes/acetravel/assets/fonts/visbycf-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'visby_cfthin';
    src: url('/wp-content/themes/acetravel/assets/fonts/visbycf-thin-webfont.woff2') format('woff2'),
         url('/wp-content/themes/acetravel/assets/fonts/visbycf-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
body {
	font-family: $font-family-sans-serif !important;
	font-size: 18px !important;
}

h1, h2, h3, h4 {
	color: $primary;
	margin-bottom: 50px !important;
    font-family: $font-family-monospace;

	span,
	strong {
		color: $secondary !important;
        font-family: $font-family-monospace !important;
	}
}

h1 {
	font-weight: 700 !important;
}

h2 {
	font-weight: 700 !important;
}

// Lists
ul {
	margin-left: 0 !important;
	padding-left: 1rem !important;
}

li {
	margin-left: 0;
}

.list-column {
  ul {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
  }
}

.primary-list {
  li {
    color: $primary;
    list-style: none;
    position: relative;
    padding-left: 10px;
    padding-bottom: 10px;

    &:before {
      content: '';
      background-image: url("/wp-content/themes/acetravel/assets/images/plane-icon-primary.svg");
      position: absolute;
      left: -20px;
      top: 4px;
      height: 20px;
      width: 20px;
      background-size: cover;
    }
  }
}

.secondary-list {
  li {
    color: $secondary;
    list-style: none;
    position: relative;
    padding-left: 10px;
    padding-bottom: 10px;

    &:before {
      content: '';
      background-image: url("/wp-content/themes/acetravel/assets/images/plane-icon-secondary.svg");
      position: absolute;
      left: -20px;
      top: 4px;
      height: 20px;
      width: 20px;
      background-size: cover;
    }
  }
}

// Links
a {
	color: $secondary;
}

a:visited {
	color: $secondary;
}