// Buttons
.btn {
	border: 2px solid $primary !important;
	border-radius: 50px !important;
	color: $primary !important;
	margin-top: 50px;
	margin-bottom: 50px;
	margin-right: 15px;
}

.btn-primary {
	background: transparent !important;

	&:hover {
		color: white !important;
		background-color: $primary !important;
	}
}

.btn-secondary {
	background: $secondary !important;
	color: white !important;
	border-color: $secondary !important;

	&:hover {
		color: $secondary !important;
		background-color: transparent !important;
	}
}

.btn-solid-primary {
	background: $primary !important;
	color: white !important;

	&:hover {
		background-color: transparent !important;
		color: $primary !important;
	}
}

.btn-transparent-white {
	background: transparent !important;
	border-color: white !important;
	color: white !important;

	&:hover {
		color: $primary !important;
		background-color: white !important;
	}
}

.btn-transparent-secondary {
	background-color: transparent !important;
	border-color: $secondary !important;
	color: $secondary !important;

	&:hover {
		color: white !important;
		background-color: $secondary !important;
	}
}